<template>
  <v-app >
    <v-main style="background-color: #F9F9F9" >
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  
  },

  data: () => ({
    //
  }),
};
</script>
<style scoped>
html, body, h3 {
  font-family: 'Poppins', sans-serif;
}
</style>
