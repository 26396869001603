import page from './components/new.vue';
import HelloWorld from './components/HelloWorld.vue';


export const routes = [

	{path: '/', component: HelloWorld},
	{path: '/product', component: page},
	
	{path: '*', redirect: '/' }
];