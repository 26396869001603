<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8">
        <v-card
          style="
            border: 1px solid #eb5b4e;
            border-radius: 25px !important;
            box-shadow: unset;
          "
          class="pa-2"
        >
          <v-row>
            <v-col cols="4">
              <h3
                class="mt-2.5 ml-3"
                style="font-size: 20px; font-weight: bold; font: normal Poppins"
              >
                GET POINTS
              </h3>
            </v-col>
            <v-col cols="8">
              <ul
                style="margin-bottom: 0px !important; margin-top: -10px"
                class="
                  list-none
                  bg-gray-900
                  h-16
                  bg-opacity-30
                  px-2
                  rounded-pill
                  text-center
                  overflow-auto
                  whitespace-nowrap
                "
                :class="{
                  'flex items-center mb-6': variant === 'horizontal',
                }"
              >
                <li
                  style="text-transform: uppercase; font-size: 15px"
                  v-for="(tab, index) in tabList"
                  :key="index"
                  class="w-full px-4 h-12 py-1.5 rounded-xl"
                  :class="{
                    'text-white orangecolor  shadow-xl':
                      index + 1 === activeTab,
                    'text-black': index + 1 !== activeTab,
                  }"
                >
                  <label
                    :for="`${_uid}${index}`"
                    v-text="tab"
                    class="cursor-pointer mt-2 block"
                  />
                  <input
                    :id="`${_uid}${index}`"
                    type="radio"
                    :name="`${_uid}-tab`"
                    :value="index + 1"
                    v-model="activeTab"
                    class="hidden"
                    @click="toggle = !toggle"
                  />
                </li>
              </ul>
            </v-col>
          </v-row>

          <!-- <tabs class="w-11/12 lg:w-10/12 mx-auto mb-16" :tabList="tabList">
      
      <template v-slot:tabPanel-1>
          <img src="../assets/diamond.png" alt="">
      </template>
      <template v-slot:tabPanel-2> 
        <img src="../assets/diamond2.png" alt="">
      </template> -->

          <!--     
    </tabs> -->
        </v-card>
        <v-row class="text-center mt-4">
          <v-col cols="6">
            <v-select :items="cat" label="Categories" dense standard></v-select>
          </v-col>
          <v-col cols="6">
            <v-select :items="sort" label="Sort By" dense Filled style></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" md="4">
        <v-card
          style="background-color: #eb5b4e; border-radius: 25px !important"
          class="pa-2 h-40 justify-items-center flex-col align-middle"
          outlined
          tile
        >
          <div class="grid grid-cols-5 gap-4 mt-4 justify-items-center">
            <div class="align-middle flex-col col-span-2 ml-4">
              <img src="../assets/diamond.png" alt="" />
            </div>
            <div
              class="col-span-3 mt-3"
              style="
                text-align: left;
                font: 600 14px Poppins;
                letter-spacing: 0px;
              "
            >
              <p style="margin-bottom: 0;color: white;opacity: 0.5;text-align: center;">POINTS YOU'VE EARNED</p>
              <h1 class="text-6xl"
                style="font-weight: bold; color: white; font: Poppins;"
              >
                {{ points }}
              </h1>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="!toggle" cols="12">
        <v-row>
          <v-col 
            cols="2"
            v-for="(next, i) in offers"
            :key="i"
            style="height: 350px !important"
          >
          <router-link to="/product" style="text-decoration: none">
            <figure class="bg-gray-100 rounded-xl p-3">
              <img
                class="w-32 h-32 rounded-xl mx-auto"
                :src="next.creative"
                alt=""
                width="384"
                height="512"
              />
              <div class="pt-6 text-center space-y-4">
                <blockquote>
                  <p style="font-size: 12px; line-height: 15px;height: 40px !important; font: Montserrat !important; color:#36465D" class="text-lg font-semibold mt-3 -mb-3">
                    {{ next.name }}
                  </p>
                </blockquote>
                <figcaption class="font-medium">
                  <button
                    class="btn btn-secondary"
                    style="background-color: #36465d"
                  >
                    {{ next.currency }}
                    <span
                      style="
                        float: left;
                        position: relative;
                        margin-right: 10px;
                        margin-top: 2px;
                      "
                    >
                      <img
                        width="16"
                        height="16"
                        src="../assets/diamond.png"
                        alt=""
                      />
                    </span>
                  </button>
                </figcaption>
              </div>
            </figure>
          </router-link>
          </v-col>
          
        </v-row>
      </v-col>
      <v-col v-if="toggle" cols="12">
        <v-row>
          <v-col
            cols="2"
            v-for="(next, i) in surveys"
            :key="i"
            style="height: 350px !important"
          >
            <figure class="bg-gray-100 rounded-xl p-8">
              <img
                class="w-32 h-32 rounded-xl mx-auto"
                src="../assets/diamond.png"
                alt=""
                width="384"
                height="512"
              />
              <div class="pt-6 text-center space-y-4">
                <blockquote>
                  <p style="font-size: 12px" class="text-lg font-semibold">
                    {{ next.currency }}
                  </p>
                </blockquote>
                <figcaption class="font-medium">
                  <button
                    class="btn btn-secondary"
                    style="background-color: #36465d"
                  >
                    {{ next.estimated_time }} Min
                  </button>
                </figcaption>
              </div>
            </figure>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Tabs from "./tab.vue";

const axios = require("axios");
export default {
  name: "HelloWorld",
  components: {
    // Tabs
  },

  data: () => ({
    tab: null,
    items: ["Offers", "Surveys"],
    cat: [
      "All",
      "Free",
      "Purchase",
      "Trial",
      "Other",
      "Mobile Apps",
      "Clicks/Videos",
      "Studies",
      "Surveys",
    ],
    sort: ["Most Popular", "Highest Paying", "Newest"],
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    expand: false,
    expand2: false,
    points: "",
    offers: [],
    surveys: [],
    tabList: ["Offers", "Surveys"],
    activeTab: 1,
    variant: "horizontal",
  }),
  methods: {
    po() {
      alert("Click Function");
    },
    loadproducts() {
      var self = this;

      axios
        .get("https://api-sdk.revenueuniverse.com/profile.php?api_key=62&uid=1")
        .then(function (response) {
          // handle success
          console.log(response);
          self.points = response.data.currency_earned;
          const url = new URL(response.data.offers_url);
          const url2 = new URL(response.data.surveys_url);
          // let searchParams = new URLSearchParams(url)
          const para1 = url.searchParams.get("api_key");
          const para2 = url.searchParams.get("id");
          const para3 = url2.searchParams.get("id");
          const para4 = url2.searchParams.get("key");
          console.log(para3, para4);
          axios
            .get("https://" + url.host + url.pathname, {
              params: {
                id: para2,
                api_key: para1,
                os: "ios",
              },
            })
            .then(function (response1) {
              // handle success
              console.log(response1.data.offers);
              self.offers = response1.data.offers;
            });
          axios
            .get("https://" + url2.host + url2.pathname, {
              params: {
                id: para3,
                key: para4,
                format: "json",
              },
            })
            .then(function (response2) {
              // handle success
              console.log(response2);
              self.surveys = response2.data.surveys;
            });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },
  },
  mounted() {
    this.loadproducts();
  },
};
</script>
<style scoped>
html,
body,
h3 {
  font-family: "Poppins", sans-serif;
}
.orangecolor {
  background-color: #eb5b4e;
}
</style>
