import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';

import { routes } from './routes';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "tailwindcss/dist/tailwind.css";


Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vuetify from './plugins/vuetify'

Vue.use(VueRouter);
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

const router = new VueRouter({
	mode: 'history',
	routes
});



new Vue({
	el: '#app',
	router,
	vuetify,
	render: h => h(App)
})