<template>
  <v-container>
    <v-row class="grid justify-items-center">
      <v-col cols="12" md="8" class="">
        <figure class="bg-gray-100 rounded-xl p-16">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-4">
              <img
                class="w-64 h-64 rounded-xl mx-auto"
                src="https://cdn.revucreatives.com/creatives/5ccfc7eabf3507d3e2ba1d190a8ff75d.jpg"
                alt=""
                width="384"
                height="512"
              />
            </div>
            <div class="col-span-8">
              <blockquote>
                <p
                  style="
                    text-align: left;
                    font: bold 32px Poppins;
                    letter-spacing: 0px;
                    color: #36465d;
                    text-transform: capitalize;
                  "
                  class="text-lg font-semibold"
                >
                  Take The Quick Questionnaire!
                </p>
              </blockquote>

              <figcaption class="font-medium">
                <p
                  style="
                    text-align: left;
                    font: normal 300 20px Roboto;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 0.56;
                  "
                >
                  Requirements: Get Points for every survey you complete. You
                  may complete this unlimited times.
                </p>
              </figcaption>
              <v-row>
                <v-col cols="6" style="margin-bottom: -13px;">
                     <v-btn class="w-56" style="text-align: center;font: normal normal bold 17px Playfair Display;letter-spacing: 0px;color: #FFFFFF;"
              color="error" dark x-large> Earn 10,159 Points</v-btn>
                </v-col>
                   
              </v-row>
              <v-row>
                 <v-col cols="6">
                   <router-link to="/" style="text-decoration: none">
                       <v-btn class="w-56" style="background-color:#1B9EB3;text-align: center;font: normal normal bold 17px Playfair Display;letter-spacing: 0px;color: #FFFFFF;"
              dark x-large> GO BACK TO OFFERS</v-btn>
                   </router-link>
                    
                </v-col>
                  
              </v-row>
            </div>
          </div>
        </figure>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Tabs from "./tab.vue";

const axios = require("axios");
export default {
  name: "HelloWorld",
  components: {
    // Tabs
  },

  data: () => ({
    tab: null,
    items: ["Offers", "Surveys"],
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    expand: false,
    expand2: false,
    points: "",
    offers: [],
    surveys: [],
    tabList: ["Offers", "Surveys"],
    activeTab: 1,
    variant: "horizontal",
  }),
  methods: {
    po() {
      alert("Click Function");
    },
    loadproducts() {
      var self = this;

      axios
        .get("https://api-sdk.revenueuniverse.com/profile.php?api_key=62&uid=1")
        .then(function (response) {
          // handle success
          console.log(response);
          self.points = response.data.currency_earned;
          const url = new URL(response.data.offers_url);
          const url2 = new URL(response.data.surveys_url);
          // let searchParams = new URLSearchParams(url)
          const para1 = url.searchParams.get("api_key");
          const para2 = url.searchParams.get("id");
          const para3 = url2.searchParams.get("id");
          const para4 = url2.searchParams.get("key");
          console.log(para3, para4);
          axios
            .get("https://" + url.host + url.pathname, {
              params: {
                id: para2,
                api_key: para1,
                os: "ios",
              },
            })
            .then(function (response1) {
              // handle success
              console.log(response1.data.offers);
              self.offers = response1.data.offers;
            });
          axios
            .get("https://" + url2.host + url2.pathname, {
              params: {
                id: para3,
                key: para4,
                format: "json",
              },
            })
            .then(function (response2) {
              // handle success
              console.log(response2);
              self.surveys = response2.data.surveys;
            });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    },
  },
  mounted() {
    this.loadproducts();
  },
};
</script>
<style scoped>
.orangecolor {
  background-color: #eb5b4e;
}
</style>
